import { Auth0Provider } from "@auth0/auth0-react"
import { FC, PropsWithChildren } from "react";
import { createBrowserHistory } from "history";

 export const history = createBrowserHistory();
 const onRedirectCallback = async (appState:any) => {
 history.replace({
   pathname: appState?.returnTo || `${window.location.pathname}`
   });
};

export type AuthBootstrapProps = {
  auth0Domain: string;
  auth0ClientId: string;
};
export const AuthBootstrap: FC<PropsWithChildren<AuthBootstrapProps>> = ({ auth0Domain, auth0ClientId, children }) => {
  const origin = typeof window !== "undefined" ? window.location.origin : "";

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      authorizationParams={{
        audience: `https://${auth0Domain}/api/v2/`,
        redirect_uri: `${origin}/auth/callback`,
        scope: "openid profile email offline_access",
      }}
      onRedirectCallback={onRedirectCallback}
    >
     {children}
    </Auth0Provider>
  );
};

