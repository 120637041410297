import React, { forwardRef, Fragment, useRef } from "react";
import Styles from "./Filters.module.css";

export default forwardRef((props: any, ref) => {
  const inputRef = useRef<HTMLSelectElement | null>(null);

  const onInputBoxChanged = (input: React.ChangeEvent<HTMLSelectElement>) => {
    props.parentFilterInstance((instance: any) => {
      const params = {
        firstParam: null,
        secondParam: null,
      };
      const inputParams =
        input.target.value === ""
          ? params
          : { firstParam: "equals", secondParam: input.target.value };
      instance.onFloatingFilterChanged(
        inputParams.firstParam,
        inputParams.secondParam
      );
    });
  };

  return (
    <Fragment>
      <select
        ref={inputRef}
        onChange={onInputBoxChanged}
        className={Styles.selectFilter}
      >
        Home Delivery, Collect from Store, Collect from External Pickup point, 
        Collect from Internal Pickup point, Collect from Mobile Pickup point-CCNY,
        Collect from Lockers and Customer Returns
        <option value=""></option>
        <option value="Home Delivery">Home Delivery</option>
        <option value="Collect from Store">Collect from Store</option>
        <option value="Collect from External Pickup point">
        Collect from External Pickup point
        </option>
        <option value="Collect from Internal Pickup point">
        Collect from Internal Pickup point
        </option>
        <option value="Collect from Mobile Pickup point-CCNY">
        Collect from Mobile Pickup point-CCNY
        </option>
        <option value="Collect from Locker">Collect from Locker</option>
        <option value="Customer Returns">Customer Returns</option>
      </select>
    </Fragment>
  );
});
