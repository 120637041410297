import { KCIDetailsActionTypes } from "../action-types";
import { getKCIDetailsActions } from "../actions";
import { KCIDeatilsPayload } from "../actions/typeDefs";

export interface KCIDetails {
  data: KCIDeatilsPayload | undefined;
  error: string | null;
  loading: boolean;
}

const initialState = {
  data: undefined,
  error: null,
  loading: false,
};

const kciDetailsReducer = (
  state: KCIDetails = initialState,
  action: getKCIDetailsActions
): KCIDetails => {
  switch (action.type) {
    case KCIDetailsActionTypes.GET_KCI_DETAILS:
      return { data: undefined, error: null, loading: true };
    case KCIDetailsActionTypes.GET_KCI_DETAILS_SUCCESS:
      return { data: action.payload, error: null, loading: false };
    case KCIDetailsActionTypes.GET_KCI_DETAILS_ERROR:
      return { data: undefined, error: action.payload, loading: false };
    default:
      return state;
  }
};

export default kciDetailsReducer;
