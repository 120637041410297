export enum CountryKCIActionTypes {
  GET_COUNTRY_KCI = "getCountryKCI",
  GET_COUNTRY_KCI_SUCCESS = "getCountryKCISuccess",
  GET_COUNTRY_KCI_ERROR = "getCountryKCIError",
}

export enum KCIDetailsActionTypes {
  GET_KCI_DETAILS = "getKCIDetails",
  GET_KCI_DETAILS_SUCCESS = "getKCIDetailsSuccess",
  GET_KCI_DETAILS_ERROR = "getKCIDetailsError",
}
