import { FC, PropsWithChildren, useEffect } from "react";
import { Loading } from "../components/layout";
import { useAuth } from "./useAuth";

export type AuthGatewayProps = {
  authRequired?: boolean;
};

export const AuthGateway: FC<PropsWithChildren<AuthGatewayProps>> = ({ authRequired = true, children }) => {
  const { isLoading, isAuthenticated, error, loginWithRedirect } = useAuth();

  const needDoLoginWithRedirect = authRequired && !isAuthenticated && !isLoading && !error;
  useEffect(() => {
    needDoLoginWithRedirect && (async (): Promise<void> => loginWithRedirect())();
  }, [needDoLoginWithRedirect, loginWithRedirect]);

  if (error) {
    // just re-throw the error, so re report it to sentry and display dedicated fallback message
    // in case we do not want to report all auth-related errors (e.g. invalid auth code, add the
    // appropriate rule to 'beforeSend' in sentry.client.config.ts
    throw error;
  } else if (authRequired && isLoading) {
    return <Loading className="flex-grow-[0.6]" />;
  } else if (!authRequired || isAuthenticated) {
    return <>{children}</>;
  } else {
    return null;
  }
};
