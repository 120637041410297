import { Dispatch } from "redux";
import { KCIDetailsActionTypes } from "../action-types";
import axios from "axios";
import { getKCIDetailsActions } from "../actions/getKCIDetails";

export const getKCIDetails = (kciDetailsInput: string, accessToken: string) => {
  return async (dispatch: Dispatch<getKCIDetailsActions>) => {
    dispatch({ type: KCIDetailsActionTypes.GET_KCI_DETAILS });
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_CONFIG_URL}/configs/configdetail/${kciDetailsInput}`,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch({
        type: KCIDetailsActionTypes.GET_KCI_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (e: any) {
      dispatch({
        type: KCIDetailsActionTypes.GET_KCI_DETAILS_ERROR,
        payload: e.message,
      });
    }
  };
};
