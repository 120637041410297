export const toPascalCaseTransportType = (sentence: string) =>
  sentence
    .split(",")
    .map((word) => word[0].toUpperCase().concat(word.slice(1)))
    .join(", ");

export const toPascalCaseNotfnName = (sentence: string) =>
  sentence
    .split(" ")
    .map((word) => {
      if (word.toLowerCase() === "cdc") {
        return word.toUpperCase();
      }
      if (word.toLowerCase() === "tsp") {
        return word.toUpperCase();
      }      
      if (word.toLowerCase() === "pup") {
        return word.toUpperCase();
      }            
      return word[0]?.toUpperCase()?.concat(word.slice(1));
    })
    .join(" ");
