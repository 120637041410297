import { createContext, PropsWithChildren, useState } from "react";

type CountryType = {
  id: string;
  name: string;
  ISOcode: string;
  orgcode: string;
};

interface ICountryContext {
  countries: CountryType[];
  setCountries: React.Dispatch<React.SetStateAction<CountryType[]>>;
}


export const CountryContext = createContext<ICountryContext | null>(null);

const CountryContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [countries, setCountries] = useState<CountryType[]>([]);

  return (
    <CountryContext.Provider value={{ countries, setCountries }}>
      {children}
    </CountryContext.Provider>
  );
};

export default CountryContextProvider;
