import { Dispatch } from "redux";
import { CountryKCIActionTypes } from "../action-types";
import axios from "axios";
import { getCountryKCIActions } from "../actions";

export const getCountryKCI = (country: string, accessToken: string) => {
  return async (dispatch: Dispatch<getCountryKCIActions>) => {
    dispatch({ type: CountryKCIActionTypes.GET_COUNTRY_KCI });
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_CONFIG_URL}/configs/eventconfig/${country}`,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch({
        type: CountryKCIActionTypes.GET_COUNTRY_KCI_SUCCESS,
        payload: data,
      });
    } catch (e: any) {
      dispatch({
        type: CountryKCIActionTypes.GET_COUNTRY_KCI_ERROR,
        payload:
          "Unfortunately, we couldn't get the configuration at the moment. Please, try in some time!",
      });
    }
  };
};
