import React, { createContext, PropsWithChildren, SetStateAction, useState } from "react";

interface IDetailsContext {
  orgCode: string;
  notfnName: string;
  system: string;
  countryName: string;
  excludedDeliveryFlows: string[];
  setOrgCode: React.Dispatch<SetStateAction<string>>;
  setNotfnName: React.Dispatch<SetStateAction<string>>;
  setCountryName: React.Dispatch<SetStateAction<string>>;
  setExcludedDeliveryFlows: React.Dispatch<SetStateAction<string[]>>;
  setSystem: React.Dispatch<SetStateAction<string>>;
}

export const DetailsContext = createContext<IDetailsContext | null>(null);

const DetailsContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [orgCode, setOrgCode] = useState("");
  const [notfnName, setNotfnName] = useState("");
  const [countryName, setCountryName] = useState("");
  const [system, setSystem] = useState("");
  const [excludedDeliveryFlows, setExcludedDeliveryFlows] = useState([""]);

  return (
    <DetailsContext.Provider
      value={{
        orgCode,
        notfnName,
        countryName,
        excludedDeliveryFlows,
        setExcludedDeliveryFlows,
        setNotfnName,
        setOrgCode,
        setCountryName,
        system,
        setSystem,
      }}
    >
      {children}
    </DetailsContext.Provider>
  );
};

export default DetailsContextProvider;
