export const deviationIcon = (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 0H0V20H12V18H2V2H14V8H16V0Z" fill="#111111" />
    <path d="M12 4H4V6H12V4Z" fill="#111111" />
    <path d="M9 8H4V10H9V8Z" fill="#111111" />
    <path d="M16 10H14V17H16V10Z" fill="#111111" />
    <path
      d="M15 20C15.5523 20 16 19.5523 16 19C16 18.4477 15.5523 18 15 18C14.4477 18 14 18.4477 14 19C14 19.5523 14.4477 20 15 20Z"
      fill="#111111"
    />
  </svg>
);

export const buIcon = (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 16V0H0V16H20ZM18 2H2V14H5V6H15V14H18V2ZM9 8H7V14H9V8ZM11 14H13V8H11V14Z"
      fill="#111111"
    />
  </svg>
);

export const tspIcon = (
  <svg
    width="23"
    height="17"
    viewBox="0 0 23 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 0H15V3.00001L18.0246 3L22.0039 8.68478V14H19.3472C18.9254 15.3056 17.6999 16.25 16.2539 16.25C14.8079 16.25 13.5824 15.3056 13.1607 14H9.25634C8.83456 15.3056 7.60911 16.25 6.16309 16.25C4.71707 16.25 3.49161 15.3056 3.06984 14H0V0ZM3.06984 12C3.49161 10.6944 4.71707 9.75 6.16309 9.75C7.60911 9.75 8.83456 10.6944 9.25634 12H13V2H2V12H3.06984ZM15 10.0007C15.3858 9.83921 15.8095 9.75 16.2539 9.75C17.6999 9.75 18.9254 10.6944 19.3472 12H20.0039V9.31522L16.9833 5L15 5.00001V10.0007ZM6.16309 11.75C5.47273 11.75 4.91309 12.3096 4.91309 13C4.91309 13.6904 5.47273 14.25 6.16309 14.25C6.85344 14.25 7.41309 13.6904 7.41309 13C7.41309 12.3096 6.85344 11.75 6.16309 11.75ZM16.2539 11.75C15.5635 11.75 15.0039 12.3096 15.0039 13C15.0039 13.6904 15.5635 14.25 16.2539 14.25C16.9443 14.25 17.5039 13.6904 17.5039 13C17.5039 12.3096 16.9443 11.75 16.2539 11.75Z"
      fill="#111111"
    />
  </svg>
);

export const lscIcon = (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 16V0H0V16H20ZM18 2H2V14H5V6H15V14H18V2ZM9 8H7V14H9V8ZM11 14H13V8H11V14Z"
      fill="#111111"
    />
  </svg>
);

export const reminderIcon = (
  <svg
    width="21"
    height="16"
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.00391 0H0.00390625V1V15V16H1.00391H19.0015H20.0013L20.0015 15.0002L20.0039 1.00018L20.0041 0H19.0039H1.00391ZM2.00391 3.20815V14H18.0016L18.0035 3.21109L10.5819 8.38777L10.0101 8.7866L9.43809 8.38805L2.00391 3.20815ZM16.2439 2H3.76842L10.0095 6.34856L16.2439 2Z"
      fill="#111111"
    />
  </svg>
);

export const sgrIcon = (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.02913 8.02754C8.61492 7.44176 8.61492 6.49201 8.02913 5.90622C7.44335 5.32044 6.4936 5.32044 5.90781 5.90622C5.32203 6.49201 5.32203 7.44176 5.90781 8.02754C6.4936 8.61333 7.44335 8.61333 8.02913 8.02754Z"
      fill="#111111"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.2037 2.20346L0.950195 10.1423L11.3368 20.529L20.5292 11.3366L10.1426 0.949951L2.2037 2.20346ZM11.3368 17.7006L3.08456 9.44828L3.95237 3.95213L9.44852 3.08432L17.7008 11.3366L11.3368 17.7006Z"
      fill="#111111"
    />
  </svg>
);
