import { AuthContextInterface } from "./useAuth";

type RequestInit = Record<string, any> & {
  headers?: Record<string, string>;
};

export const withTokenAuthorization = (
  token: string,
  init: RequestInit = {}
): RequestInit => ({
  ...init,
  headers: {
    ...init.headers,
    Authorization: `Bearer ${token}`,
  },
});

export const withAccessTokenAuthorization = async (
  {
    getAccessTokenSilently,
  }: Pick<AuthContextInterface, "getAccessTokenSilently">,
  init: RequestInit = {}
): Promise<RequestInit> =>
  withTokenAuthorization(await getAccessTokenSilently(), init);

export const withIdTokenAuthorization = async (
  { getIdTokenClaims }: Pick<AuthContextInterface, "getIdTokenClaims">,
  init: RequestInit = {}
): Promise<RequestInit> =>
  // in case we call our own backend, we can use the id_token, as it's audience is our client, which we can verify;
  // once we switch to @auth0/auth-nextjs we could get rid of it and just verify the session
  withTokenAuthorization((await getIdTokenClaims())?.__raw as string, init);
