import { User as Auth0User } from "@auth0/auth0-react";
import { ActiveUser, convertActiveUser } from "@coworker-design/layout-react";

const IKEA_CLAIMS_DOMAIN = "https://accounts.ikea.com" as const;

export type User = ActiveUser & {
  networkId?: string | null;
};

export const convertToUser = (
  auth0User: Auth0User,
  prefix = IKEA_CLAIMS_DOMAIN
): User => ({
  ...convertActiveUser(auth0User, prefix),
  networkId: auth0User[`${prefix}/networkId`],
});
