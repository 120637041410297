import React, { useCallback, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import CountryFilter from "./CountryFilter";
import FlowFilter from "./FlowFilter";
import PopupCellRenderer from "./PopupCellRenderer";
import StatusFilter from "./StatusFilter";
import TransportTypeFilter from "./TransportTypeFilter";
import { IData } from "../state/reducers/homeScreenReducer";
import {
  toPascalCaseNotfnName,
  toPascalCaseTransportType,
} from "../utils/formatMessage";
import Button from "@ingka/button";
interface AgGridProps {
  data: IData[];
}
const AgGrid: React.FC<AgGridProps> = ({ data }) => {
  const gridRef = useRef<AgGridReact | null>(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState<IData[]>();
  const [newColDefs] = useState([
    {
      headerName: "Name",
      field: "name",
      filter: true,
      flex: 9,
      suppressMenu: true,
    },
    {
      headerName: "Country",
      field: "country",
      flex: 5,
      filter: "agTextColumnFilter",
      suppressMenu: true,
      floatingFilterComponent: CountryFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        color: "red",
      },
    },
    {
      headerName: "Order Flow",
      field: "deliveryFlow",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      floatingFilterComponent: FlowFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        color: "red",
      },
      flex: 6,
    },
    {
      headerName: "Transport Type",
      field: "transportType",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      floatingFilterComponent: TransportTypeFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        color: "red",
      },
      flex: 4,
    },
    {
      headerName: "Status",
      field: "status",
      flex: 4,
      filter: "agTextColumnFilter",
      suppressMenu: true,
      floatingFilterComponent: StatusFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        color: "red",
      },
      cellStyle: (params: any) =>
        params.value === "Enabled"
          ? { color: "#0058A3", fontWeight: 600 }
          : { color: "#929292", fontWeight: 600 },
    },
    {
      flex: 4,
      cellRendererFramework: (params: any) => (
        <PopupCellRenderer notification={params.data} />
      ),
      filter: false,
    },
  ]);
  const defaultColDef = {
    sortable: true,
    floatingFilter: true,
  };
  const rowStyle = { background: "white" };

  const onGridReady = useCallback(() => {
    const updatedData = data.map((item) => {
      const name = toPascalCaseNotfnName(item.eventName?.toLowerCase());
      const transportType = toPascalCaseTransportType(
        item.transportType.toLowerCase()
      );
      return { ...item, name, transportType };
    });

    setRowData(updatedData);
  }, []);

  const getParams = () => {
    return {
      fileName: `Configs_Summary${rowData ? "_" + rowData[0].country : ""}`,
    };
  };
  const onBtnExport = useCallback(() => {
    gridRef.current!.api.exportDataAsCsv(getParams());
  }, []);

  const setAutoHeight = useCallback(() => {
    gridRef.current!.api.setDomLayout("autoHeight");
    // auto height will get the grid to fill the height of the contents,
    // so the grid div should have no height set, the height is dynamic.
    (document.querySelector<HTMLElement>("#myGrid")! as any).style.height = "";
  }, []);

  return (
    <div style={containerStyle}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "99%",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "99%",
            justifyContent: "right",
          }}
        >
          <Button onClick={onBtnExport} text="Download Summary" />
        </div>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={newColDefs}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            rowStyle={rowStyle}
            domLayout={"autoHeight"}
            animateRows={true}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
};

export default AgGrid;
