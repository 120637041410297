import React from "react";
import Table, { TableHeader, TableBody } from "@ingka/table";
import "@ingka/table/dist/style.css";
import Styles from "./DetailsTable.module.css";

interface TableHeading {
  header1: string;
  header2: string;
  header3: string;
}
interface TableDetails {
  data1: string | undefined;
  data2: string | undefined;
  data3: string | undefined;
}
interface DetailsTableProps {
  tableDetails: TableDetails[] | null;
  tableHeading: TableHeading;
  configurationName: string;
}

export const DetailsTable: React.FC<DetailsTableProps> = ({
  tableDetails,
  tableHeading,
  configurationName,
}) => {
  return (
    <React.Fragment>
      <div data-testid="details-table">
        {!tableDetails ? (
          `All ${configurationName}s are enabled for this notification`
        ) : (
          <Table style={{ width: "100%" }} key={configurationName}>
            <TableHeader sticky={true} className={Styles.tableHeader}>
              <tr key="theader">
                <th className={Styles.headerData} key="header1">
                  {tableHeading.header1}
                </th>
                <th className={Styles.headerData} key="header2">
                  {tableHeading.header2}
                </th>
                <th className={Styles.headerData} key="header3">
                  {tableHeading.header3}
                </th>
              </tr>
            </TableHeader>
            <TableBody className={Styles.tableBody}>
              {tableDetails?.map(
                ({ data1, data2, data3 }: TableDetails, index: number) => {
                  return (
                    <tr key={index}>
                      <td className={Styles.tableData} key="data1">
                        {data1}
                      </td>
                      <td className={Styles.tableData} key="data2">
                        {data2}
                      </td>
                      <td
                        className={Styles.tableData}
                        key="data3"
                        style={{
                          fontWeight:
                            data3?.toLowerCase() === "disabled" ||
                            data3?.toLowerCase() === "enabled"
                              ? 700
                              : 100,
                          color:
                            data3?.toLowerCase() === "disabled"
                              ? "#767676"
                              : data3?.toLowerCase() === "enabled"
                              ? "#0058A3"
                              : "#111111",
                        }}
                      >
                        {data3}
                      </td>
                    </tr>
                  );
                }
              )}
            </TableBody>
          </Table>
        )}
      </div>
    </React.Fragment>
  );
};
