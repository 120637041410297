import React, { useContext, useEffect } from "react";
import Text from "@ingka/text";
import Styles from "./SelectCountry.module.css";
import SelectDropDown from "../components/SelectDropDown";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/focus/dist/style.css";
import { useNavigate } from "react-router-dom";
import { ViewKCIContext } from "../contexts/ViewKCIContext";
import { CountryContext } from "../contexts/CountriesContext";
import { useAuth, withIdTokenAuthorization } from "../auth";
import _ from "lodash";
import Loading, { LoadingBall } from "@ingka/loading";

const SelectCountry: React.FC = () => {
  const countryContext = useContext(CountryContext);
  const viewKCIContext = useContext(ViewKCIContext);
  const navigate = useNavigate();
  const hint = "Select a Country";
  const { getIdTokenClaims } = useAuth();
  useEffect(() => {
    (async (): Promise<void> => {
      const response = await fetch(`${process.env.REACT_APP_CONFIG_URL}/country`, await withIdTokenAuthorization({ getIdTokenClaims }));
      const responseData = await response.json();
      const sortedCountries = _.sortBy(responseData, ["name"]);
      countryContext?.setCountries(sortedCountries);
    })();
  }, []);

  const submitKCI = () => {
    navigate("/home");
  };
  return (
    <>
      {countryContext?.countries?.length == 0 ? (<Loading text="Loading..." labelTransitions>
        <LoadingBall />
      </Loading>) :
        <div className={Styles.containerDiv}>
          <Text tagName="h2" headingSize="m" className={Styles.heading}>
          <div style={{ marginBottom: 20 }}>
            Order Information
            </div>
          </Text>
         
          <div style={{ marginBottom: 20 }}>
            Choose the country you want to preview the delivery notifications      
          </div>
         
          <SelectDropDown setOption={viewKCIContext?.setCountry!} hint={hint} />
          {viewKCIContext?.country && viewKCIContext.country !== hint ? (
            <button onClick={submitKCI} className={Styles.submitBtn}>
              View notifications
            </button>
          ) : null}
        </div>
      }
    </>
  );
};

export default SelectCountry;
