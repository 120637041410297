import { Auth0ContextInterface, useAuth0 } from "@auth0/auth0-react";
import { useMemo } from "react";
import { User, convertToUser } from "./user";

export type AuthContextInterface = Omit<
  Auth0ContextInterface<User>,
  "getAccessTokenWithPopup" | "loginWithPopup" | "handleRedirectCallback"
>;

export const useAuth: () => AuthContextInterface = () => {
  const {
    // auth0 state
    error,
    isAuthenticated,
    isLoading,
    user: auth0User,
    // auth0 context (selected methods)
    getAccessTokenSilently,
    getIdTokenClaims,
    loginWithRedirect,
    logout,
  } = useAuth0();

  const user = useMemo<User | undefined>(
    () => (auth0User ? convertToUser(auth0User) : undefined),
    [auth0User]
  );

  return useMemo<AuthContextInterface>(
    () => ({
      error,
      isAuthenticated,
      isLoading,
      user,
      getAccessTokenSilently,
      getIdTokenClaims,
      loginWithRedirect,
      logout,
    }),
    [
      error,
      isAuthenticated,
      isLoading,
      user,
      getAccessTokenSilently,
      getIdTokenClaims,
      loginWithRedirect,
      logout,
    ]
  );
};
