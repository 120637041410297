import React from "react";
import { KCIDeatilsPayload } from "../state/actions/typeDefs";
import Accordion, { AccordionItem } from "@ingka/accordion";
import Text from "@ingka/text";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/accordion/dist/style.css";
import { DetailsTable } from "./DetailsTable";
import Styles from "./DetailsAccordian.module.css";
import {
  buIcon,
  deviationIcon,
  reminderIcon,
  sgrIcon,
  tspIcon,
} from "../assets/AccordionIcons";

interface DetailsAccordianProps {
  data: KCIDeatilsPayload;
}

const createTableHeader = (
  header1: string,
  header2: string,
  header3: string
) => {
  return {
    header1: header1,
    header2: header2,
    header3: header3,
  };
};

const reminderData = (data: KCIDeatilsPayload) => {
  return data.reminderConfig && data.reminderConfig?.length > 0
    ? data.reminderConfig?.map((reminder) => {
        return {
          data1: reminder.name,
          data2: `${reminder.intervalTime} ${reminder.unit}`,
          data3: reminder.rule,
        };
      })
    : null;
};

const deviationData = (data: KCIDeatilsPayload) => {
  return data.deviationReason && data.deviationReason?.length > 0
    ? data.deviationReason?.map((deviation) => {
        return {
          data1: deviation.name,
          data2: deviation.updateOn,
          data3: deviation.status,
        };
      })
    : null;
};

const businessUnitsTitle = (data: KCIDeatilsPayload) => {
  return (
    <div className={Styles.accordionIcon}>
      {buIcon}
      <Text className={Styles.accordionTitle}>
        Business Unit{" "}
        {data.businessUnits && data.businessUnits.length
          ? `(${data.businessUnits.length})`
          : ""}
      </Text>
    </div>
  );
};

const deviationTitle = (data: KCIDeatilsPayload) => {
  return (
    <div className={Styles.accordionIcon}>
      {deviationIcon}
      <Text className={Styles.accordionTitle}>
        Deviation reason{" "}
        {data.deviationReason?.length
          ? `(${data.deviationReason?.length})`
          : ""}
      </Text>
    </div>
  );
};

interface KciDetailsProps {
  data: KCIDeatilsPayload;
}

const LeadTimeConfig: React.FC<KciDetailsProps> = ({ data }) => {
  return data.leadTimeConfig ? (
    <AccordionItem
      id="leadTimeConfig"
      title={
        <div className={Styles.accordionIcon}>
          {reminderIcon}
          <Text className={Styles.accordionTitle}>Lead Time</Text>
        </div>
      }
    >
      <span>{`${data.leadTimeConfig} hours`}</span>
    </AccordionItem>
  ) : data.eventName.toLowerCase() === "notify customer" ? (
    <AccordionItem
      id="leadTimeConfig"
      title={
        <div className={Styles.accordionIcon}>
          {reminderIcon}
          <Text className={Styles.accordionTitle}>Lead Time</Text>
        </div>
      }
    >
      <span>No lead time is configured.</span>
    </AccordionItem>
  ) : null;
};

export const DetailsAccordian: React.FC<DetailsAccordianProps> = ({ data }) => {
  return (
    <React.Fragment>
      <div data-testid="details-accordion">
        <Accordion>
          {data.reminderConfig && data.reminderConfig?.length > 0 ? (
            <AccordionItem
              id="reminderConfig"
              title={
                <div className={Styles.accordionIcon}>
                  {reminderIcon}
                  <Text className={Styles.accordionTitle}>Reminder Rule</Text>
                </div>
              }
            >
              <DetailsTable
                tableDetails={reminderData(data)}
                configurationName="Reminder Config"
                tableHeading={createTableHeader(
                  "Name",
                  "Interval time",
                  "Rule"
                )}
              />
            </AccordionItem>
          ) : data.eventName.toLowerCase().indexOf("reminder") > -1 &&
            data.eventName.toLowerCase() !== "delivery reminder" ? (
            <AccordionItem
              id="reminderConfig"
              title={
                <div className={Styles.accordionIcon}>
                  {reminderIcon}
                  <Text className={Styles.accordionTitle}>Reminder Rule</Text>
                </div>
              }
            >
              No Reminder Rule is configured.
            </AccordionItem>
          ) : null}
          <AccordionItem
            id="tsp"
            title={
              <div className={Styles.accordionIcon}>
                {tspIcon}
                <Text className={Styles.accordionTitle}>
                  Transport Service Provider{" "}
                  {data.transportServiceProviders.length
                    ? `(${data.transportServiceProviders.length})`
                    : ""}
                </Text>
              </div>
            }
          >
            <DetailsTable
              tableDetails={
                data.transportServiceProviders?.length > 0
                  ? data.transportServiceProviders.map((tsp) => {
                      return {
                        data1: tsp.id,
                        data2: tsp.updateOn,
                        data3: tsp.status,
                      };
                    })
                  : null
              }
              configurationName="Transport Service Provider"
              tableHeading={createTableHeader("ID", "Updated On", "Status")}
            />
          </AccordionItem>
          <AccordionItem id="bu" title={businessUnitsTitle(data)}>
            <DetailsTable
              tableDetails={
                data.businessUnits?.length > 0
                  ? data.businessUnits.map((bu) => {
                      return {
                        data1: bu.id,
                        data2: bu.updateOn,
                        data3: bu.status,
                      };
                    })
                  : null
              }
              configurationName="Business Unit"
              tableHeading={createTableHeader("ID", "Updated On", "Status")}
            />
          </AccordionItem>
          <AccordionItem
            id="sgr"
            title={
              <div className={Styles.accordionIcon}>
                {sgrIcon}
                <Text className={Styles.accordionTitle}>
                  SGR{" "}
                  {data.salesGroups.length
                    ? `(${data.salesGroups.length})`
                    : ""}
                </Text>
              </div>
            }
          >
            <DetailsTable
              tableDetails={
                data.salesGroups?.length > 0
                  ? data.salesGroups.map((sgr) => {
                      return {
                        data1: sgr.id,
                        data2: sgr.updateOn,
                        data3: sgr.status,
                      };
                    })
                  : null
              }
              configurationName="SGR"
              tableHeading={createTableHeader("ID", "Updated On", "Status")}
            />
          </AccordionItem>
          {data.eventId.toLowerCase().trim().indexOf("delay") > -1 &&
          data.eventId.toLowerCase().trim().indexOf("retention") === -1 ? (
            <AccordionItem id="deviation" title={deviationTitle(data)}>
              <DetailsTable
                tableDetails={deviationData(data)}
                configurationName="Deviation Reason"
                tableHeading={createTableHeader("Name", "Updated On", "Status")}
              />
            </AccordionItem>
          ) : null}

          <LeadTimeConfig data={data} />

          {data.pickUpDurationConfig ? (
            <AccordionItem
              id="pickUpDurationConfig"
              title={
                <div className={Styles.accordionIcon}>
                  {reminderIcon}
                  <Text className={Styles.accordionTitle}>
                    Pick-up Duration
                  </Text>
                </div>
              }
            >
              <span>{`${data.pickUpDurationConfig} hours`}</span>
            </AccordionItem>
          ) : null}
        </Accordion>
      </div>
    </React.Fragment>
  );
};
