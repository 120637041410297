import React, { useContext, useEffect } from "react";
import { useActions } from "../hooks/useActions";
import { useTypedSelector } from "../hooks/useTypedSelector";
import Loading, { LoadingBall } from "@ingka/loading";
import Styles from "./HomeScreen.module.css";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/loading/dist/style.css";
import { ViewKCIContext } from "../contexts/ViewKCIContext";
import { useNavigate } from "react-router-dom";
import Text from "@ingka/text";
import { useDispatch } from "react-redux";
import AgGrid from "../components/AgGrid";
import { useAuth } from "../auth";

const HomeScreen: React.FC = () => {
  const { getCountryKCI } = useActions(useDispatch());
  const useAuthAsync = useAuth().getAccessTokenSilently();
  const navigate = useNavigate();
  const viewKCIContext = useContext(ViewKCIContext);
  const { data, loading, error } = useTypedSelector(
    (state) => state.homeScreen
  );
  useEffect(() => {
    const fetchDataAndDispatch = async () => {
      useAuthAsync.then((accessToken) => {
        getCountryKCI(viewKCIContext?.country!, accessToken);
      })

    };
    fetchDataAndDispatch();
  }, [viewKCIContext?.country]);
  

  const getToHome = () => {
    navigate("/country");
  };

  if(error){
    getToHome();
  }

  return (
    <div data-testid="home-screen">
      {data.length > 0 ? (
        <div style={{padding:'2rem'}}>
          <Text
            style={{
              fontSize: 24,
              fontWeight: 700,
              color: "#111111",
            }}
          >
            Delivery Notifications
          </Text>
          <AgGrid data={data} />
        </div>
      ) : (
        !loading &&
        !error && (
          <div className={Styles.noDataContainer}>
            <p>
              Oops! There is no configuration thats available for the selected
              country
            </p>
            <button className={Styles.homeBtn} onClick={getToHome}>
              Home
            </button>
          </div>
        )
      )}
      {loading && (
        <div className={Styles.loadingContainer}>
          <Loading text="Loading..." labelTransitions>
            <LoadingBall />
          </Loading>
        </div>
      )}
      {error && <div className={Styles.loadingContainer}>{error}</div>}
    </div>
  );
};


export default HomeScreen;
