import { useState, useRef, useContext } from "react";
import React from "react";
import Styles from "./PopupCellRenderer.module.css";
import Tippy from "@tippyjs/react";
import { useNavigate } from "react-router-dom";
import { NotificationData } from "../state/actions/typeDefs";
import { DetailsContext } from "../contexts/DetailsContext";

interface PopupCellRendererProps {
  notification: NotificationData;
}

const PopupCellRenderer: React.FC<PopupCellRendererProps> = ({
  notification,
}) => {
  const detailsContext = useContext(DetailsContext);
  const tippyRef = useRef(null);
  const [visible, setVisible] = useState(false);

  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const navigate = useNavigate();

  const dropDownContent = (notfn: NotificationData) => {
    return (
      <div className={Styles.menuContainer}>
        <div
          onClick={() => {
            handleViewDetails(notfn);
          }}
          className={Styles.menuItem}
        >
          View Details
        </div>
      </div>
    );
  };

  const handleViewDetails = ({ orgCode, eventId , country,system, excludedDeliveryFlows}: NotificationData) => {
    detailsContext?.setOrgCode(orgCode);
    detailsContext?.setNotfnName(eventId);
    detailsContext?.setCountryName(country)
    detailsContext?.setExcludedDeliveryFlows(excludedDeliveryFlows)
    detailsContext?.setSystem(system)
    navigate("/details");
  };

  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent(notification)}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="right"
    >
      <button
        className={Styles.actionsButton}
        onClick={visible ? hide : show}
      ></button>
    </Tippy>
  );
};

export default PopupCellRenderer;
