import React, { forwardRef, Fragment, useRef } from "react";
import Styles from "./Filters.module.css";

export default forwardRef((props: any, ref) => {
  const inputRef = useRef<HTMLSelectElement | null>(null);

  const onInputBoxChanged = (input: React.ChangeEvent<HTMLSelectElement>) => {
    // Remove the filter
    props.parentFilterInstance((instance: any) => {
      const params = {
        firstParam: null,
        secondParam: null,
      };
      const inputParams =
        input.target.value === ""
          ? params
          : { firstParam: "equals", secondParam: input.target.value };
      instance.onFloatingFilterChanged(
        inputParams.firstParam,
        inputParams.secondParam
      );
    });
  };

  return (
    <Fragment>
      <select
        ref={inputRef}
        onChange={onInputBoxChanged}
        className={Styles.selectFilter}
      >
        <option value=""></option>
        <option value="TRUCK, PARCEL">Truck, Parcel</option>
        <option value="TRUCK">Truck</option>
        <option value="PARCEL">Parcel</option>
      </select>
    </Fragment>
  );
});
