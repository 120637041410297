import React from "react";
import CheckboxGroup from "@ingka/checkbox-group";
import FormField from "@ingka/form-field";

interface DetailsHeaderChecksListProps {
  checkedItems: string[];
  checkboxes: string[];
  additionalAttr?: boolean;
}

export const DetailsHeaderChecksList: React.FC<
  DetailsHeaderChecksListProps
> = ({ checkedItems, checkboxes, additionalAttr }) => {
  const CheckboxItemsArray = checkboxes?.map((checkbox: string, i: number) => {
    const isChecked =
      checkedItems.join().trim().toUpperCase().indexOf(checkbox.toUpperCase()) >
      -1;
    return {
      id: i,
      label: checkbox,
      disabled: false,
      checked: additionalAttr
        ? checkedItems.join().trim().toUpperCase() === checkbox.toUpperCase()
        : isChecked,
    };
  });

  return (
    <FormField style={{ margin: 0 }}>
      <CheckboxGroup list={CheckboxItemsArray} />
    </FormField>
  );
};
