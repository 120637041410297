import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as _ from "lodash";
import "./App.module.css";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/loading/dist/style.css";
import { LoadingBall } from "@ingka/loading";
import { Loading } from "../components/layout/Loading";


const App: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    (async (): Promise<void> => {
       navigate("/country");
    })();
  }, []);

  return (
    <React.Fragment>
      (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",
            marginTop: "5vh",
          }}
        >
          <Loading text="Loading...." labelTransitions>
            <LoadingBall />
          </Loading>
        </div>
      ) : (
        <div>Welcome!</div>
      )
    </React.Fragment>
  );
};
export default App;
