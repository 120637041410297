import React, { createContext, PropsWithChildren, useState } from "react";

export interface IViewKCIContext {
  country: string;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
}

export const ViewKCIContext = createContext<IViewKCIContext | null>(null);

const ViewKCIContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [country, setCountry] = useState("");
  return (
    <ViewKCIContext.Provider
      value={{ country: country, setCountry: setCountry }}
    >
      {children}
    </ViewKCIContext.Provider>
  );
};

export default ViewKCIContextProvider;
