import React, { useEffect, useContext, useState } from "react";
import { useActions } from "../hooks/useActions";
import { useTypedSelector } from "../hooks/useTypedSelector";
import Loading, { LoadingBall } from "@ingka/loading";
import Styles from "./NotfnDetails.module.css";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/loading/dist/style.css";
import { KCIDetailsComponent } from "../components/KCIDetailsComponent";
import { DetailsContext } from "../contexts/DetailsContext";
import { useDispatch } from "react-redux";
import { useAuth } from "../auth";
import { useNavigate } from "react-router-dom";

const NotfnDetails: React.FC = () => {
  const navigate = useNavigate();
  const detailsContext = useContext(DetailsContext);
  const [country, setCountry] = useState(detailsContext?.countryName);
  const [excludedDeliveryFlows, setExcludedDeliveryFlows] = useState(
    detailsContext?.excludedDeliveryFlows
  );
  const useAuthAsync = useAuth().getAccessTokenSilently();

  useEffect(() => {
    const fetchDataAndDispatch = async () => {
      useAuthAsync.then((accessToken) => {
        getKCIDetails(
          `${detailsContext?.orgCode}/${detailsContext?.notfnName
            ?.split(" ")
            .join("_")
            .toUpperCase()}/${detailsContext?.system}`, accessToken
        );
      })

    };
    fetchDataAndDispatch();

  }, []);

  useEffect(() => {
    setCountry(detailsContext?.countryName);
    setExcludedDeliveryFlows(detailsContext?.excludedDeliveryFlows);
  }, []);

  const { getKCIDetails } = useActions(useDispatch());

  const { data, loading, error } = useTypedSelector((state) => state.kciDetails);

  const getToHome = () => {
    navigate("/country");
  };

  if(error){
    getToHome();
  }


  return (
    <div data-testid="notfn-details">
      {data && Object.keys(data).length > 0 ? (
        <KCIDetailsComponent
          data={data}
          country={country}
          excludedDeliveryFlows={excludedDeliveryFlows}
        />
      ) : loading ? (
        <div className={Styles.loadingContainer}>
          <Loading text="Loading..." labelTransitions>
            <LoadingBall />
          </Loading>
        </div>
      ) : (
        <div className={Styles.loadingContainer}>'Something went Wrong!'</div>
      )}
    </div>
  );
};

export default NotfnDetails;
