import { CountryKCIActionTypes } from "../action-types";
import { getCountryKCIActions } from "../actions";
export type IData = {
  eventId: string;
  eventName: string;
  country: string;
  orgCode: string;
  deliveryFlow: string;
  transportType: string;
  status: string;
  system: string;
};

export interface KCIData {
  data: IData[];
  error: string | null;
  loading: boolean;
}

const initialState = {
  data: [],
  error: null,
  loading: false,
};

const homeScreenReducer = (
  state: KCIData = initialState,
  action: getCountryKCIActions
): KCIData => {
  switch (action.type) {
    case CountryKCIActionTypes.GET_COUNTRY_KCI:
      return { data: [], error: null, loading: true };
    case CountryKCIActionTypes.GET_COUNTRY_KCI_SUCCESS:
      return { data: action.payload, error: null, loading: false };
    case CountryKCIActionTypes.GET_COUNTRY_KCI_ERROR:
      return { data: [], error: action.payload, loading: false };
    default:
      return state;
  }
};

export default homeScreenReducer;
