import React, { SetStateAction, useContext } from "react";
import Select from "@ingka/select";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/forms/dist/style.css";
import { CountryContext } from "../contexts/CountriesContext";

interface ISelect {
  hint: string;
  setOption: React.Dispatch<SetStateAction<string>>;
}
const SelectDropDown: React.FC<ISelect> = ({ setOption, hint }) => {
  const countryContext = useContext(CountryContext);
  return (
    <Select
      id="country"
      onChange={(e) => setOption(e.target.value)}
      hintText={hint}
      style={{ width: 250, fontSize: 16 }}
    >
      {countryContext?.countries?.map(({ orgcode, name }, i) => (
        <option key={i} value={orgcode}>
          {name}
        </option>
      ))}
    </Select>
  );
};

export default SelectDropDown;
