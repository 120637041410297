import React from "react";
import Text from "@ingka/text";
import { KCIDeatilsPayload } from "../state/actions/typeDefs";
import Styles from "./KCIDetailsComponent.module.css";
import { DetailsAccordian } from "./DetailsAccordian";
import { Link } from "react-router-dom";
import {
  toPascalCaseNotfnName,
  toPascalCaseTransportType,
} from "../utils/formatMessage";
import { DetailsHeaderChecksList } from "./DetailsHeaderChecksList";

interface KCIDetailsComponentProps {
  data: KCIDeatilsPayload;
  country: string | undefined;
  excludedDeliveryFlows: string[] | undefined;
}

enum communicationTypeMap {
  EMAIL = "Always EMAIL",
  SMS = "Always SMS",
  EMAIL_SMS = "Always EMAIL and SMS",
  CUST_PROFILE = "As Per Customer Profile",
}

enum transportTypeMap {
  TRUCK = "Truck",
  PARCEL = "Parcel",
}

const getComnChannnel = (comnType: string) => {
  const emailComnType = comnType.trim().split(",").indexOf("EMAIL") > -1;
  const smsComnType = comnType.trim().split(",").indexOf("SMS") > -1;
  if (emailComnType && smsComnType) {
    return communicationTypeMap.EMAIL_SMS;
  }
  return `Always ${comnType}`;
};

export const KCIDetailsComponent: React.FC<KCIDetailsComponentProps> = ({
  data,
  country,
  excludedDeliveryFlows,
}) => {
  const notfnTitle = data.eventName
    ? toPascalCaseNotfnName(data.eventName.toLowerCase())
    : null;

  const transportType = data.transportType
    ? toPascalCaseTransportType(data.transportType.toLowerCase())
    : "";

  const transportTypesList = Object.values(transportTypeMap).join();

  const description = data.description
    ? data.description
    : `Notification is triggered to the customer when the order is in ${notfnTitle} status`;

  const deliveryFlow = data.deliveryFlow
    ? data.deliveryFlow.split(",").join(", ")
    : "";

  const enabledDeliveryFlows = deliveryFlow.split(",").map((dflow) => {
    let enabledDelFlows = [];
    const matched =
      excludedDeliveryFlows &&
      excludedDeliveryFlows
        ?.join()
        .trim()
        .toUpperCase()
        .indexOf(dflow.trim().toUpperCase()) > -1;
    if (!matched) {
      enabledDelFlows.push(dflow);
    }
    return enabledDelFlows.join();
  });

  const comnType = data.communicationType
    ? getComnChannnel(data.communicationType)
    : communicationTypeMap.CUST_PROFILE;

  const communicationTypeList = Object.values(communicationTypeMap).join();

  const status = data.status ? data.status : null;

  const allDeliveryFlowsExcluded = data.deliveryFlow.trim()
    .split(",")
    .every((df) => excludedDeliveryFlows?.includes(df.trim()));
  return (
    <React.Fragment>
      <div
        data-testid="kci-details-component"
        className={Styles.detailsPageContainer}
      >
        <div style={{ display: "flex", paddingTop:"10px" }}>
          <Link to="/home" className={Styles.link}>
            Delivery Notifications
          </Link>
          <div className={Styles.link}>{`>`}</div>
          <Link to="/details" className={Styles.link}>
            {notfnTitle}
          </Link>
        </div>
        <Text
          className={Styles.title}
          tagName="h1"
          bodySize="l"
          headingSize="l"
        >
          {notfnTitle}
        </Text>
        <Text className={Styles.description}>{description}</Text>
        <div className={Styles.divContainer}>
          <div className={Styles.divBlocks}>
            <Text className={Styles.kciDetailsName}>Order Flow</Text>
            <div className={Styles.kciDetailsValue}>
              <DetailsHeaderChecksList
                checkedItems={enabledDeliveryFlows}
                checkboxes={deliveryFlow.trim().split(",")}
              />
            </div>
          </div>
          <div className={Styles.divBlocks}>
            <Text className={Styles.kciDetailsName}>Transport Type</Text>
            <div className={Styles.kciDetailsValue}>
              <DetailsHeaderChecksList
                checkedItems={
                  status?.toUpperCase() === "ENABLED" ||
                  allDeliveryFlowsExcluded
                    ? transportType?.trim().split(",")
                    : []
                }
                checkboxes={transportTypesList.trim().split(",")}
              />
            </div>
          </div>
          <div className={Styles.divBlocks}>
            <Text className={Styles.kciDetailsName}>Communication Channel</Text>
            <div className={Styles.kciDetailsValue}>
              <DetailsHeaderChecksList
                checkedItems={comnType?.trim().split(",")}
                checkboxes={communicationTypeList.trim().split(",")}
                additionalAttr={comnType === communicationTypeMap.EMAIL_SMS}
              />
            </div>
          </div>
          <div className={Styles.divBlocks}>
            <Text className={Styles.kciDetailsName}>Country</Text>
            <Text className={Styles.kciDetailsValue}>{country}</Text>
          </div>
          <div className={Styles.divBlocks}>
            <Text className={Styles.kciDetailsName}>Status</Text>
            <Text
              className={Styles.kciDetailsValue}
              style={{ color: "#0058A3", fontWeight: 700 }}
            >
              {status}
            </Text>
          </div>
        </div>
        <DetailsAccordian data={data} />
      </div>
    </React.Fragment>
  );
};
