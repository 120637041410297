import {
  ConfigProps,
  ConfigProvider,
  NavItemModelReact,
  Layout
} from "@coworker-design/layout-react";
import { FC, PropsWithChildren, useCallback, useEffect, useState, ReactNode } from "react";
import { useAuth } from "../auth";
import "../styles/global.scss";
import { createBrowserHistory } from "history";
import { EnvironmentName, getVisibleApps } from "@ilo-ff/apps";
import { useAuth as mainAuth} from "@ilo-ff/auth-react";

export type ILOFFBootstrapProps = Pick<ConfigProps, "defaultTitle"> & {
  iloFfUrl: string;
  environment: EnvironmentName;
  children: ReactNode;
};

export const ILOFFBootstrap: FC<PropsWithChildren<ILOFFBootstrapProps>> = ({
  defaultTitle,
  iloFfUrl,
  environment,
  children,
}) => {
  const history = createBrowserHistory();
  const { user, getAccessTokenSilently, logout } = useAuth();
  const { tokens } = mainAuth();

  const [accessibleApps, setAccessibleApps] = useState<NavItemModelReact[]>();
  useEffect(() => {
    (async () => {
      const accessibleAppsIds = tokens?.accessTokens.map((it) => it.service);
      const visibleApps = await getVisibleApps(environment, accessibleAppsIds, iloFfUrl);
      setAccessibleApps(visibleApps as NavItemModelReact[]);
    })();
  }, [environment, iloFfUrl, getAccessTokenSilently]);

  const handleLocationChange = useCallback<
    Required<ConfigProps>["onLocationChange"]
  >(
    async (href, target, event) => {
      event.preventDefault();
      history.push(href);
    },
    [history]
  );
  const handleSeeAllApps = useCallback<
    Required<ConfigProps>["onSeeAllAppsClick"]
  >(
    async (event) => {
      event.preventDefault();
      history.push(iloFfUrl);
    },
    [history, iloFfUrl]
  );
  const handleHomeClick = useCallback<Required<ConfigProps>["onHomeClick"]>(
    async (event) => {
      event.preventDefault();
      history.push("/");
    },
    [history]
  );

  return (
    <ConfigProvider
      defaultTitle={
        (
          <span className="hover:cursor-pointer" onClick={handleHomeClick}>
            {defaultTitle}
          </span>
        ) as any as string
      }
      accessibleApps={accessibleApps}
      headerNavMode="HEADER_LINKS"
      onLocationChange={handleLocationChange}
      user={user}
      defaultLogoutCallback={logout}
      onSeeAllAppsClick={handleSeeAllApps}
      onHomeClick={handleHomeClick}
      isShowingBottomNav={false}
      isSeeAllAppsShown={true}
    >
      <Layout narrowContent={false}>{children}</Layout>
    </ConfigProvider>
  );
};
