import React, { forwardRef, Fragment, useContext, useRef } from "react";
import { CountryContext } from "../contexts/CountriesContext";
import { ViewKCIContext } from "../contexts/ViewKCIContext";
import Styles from "./Filters.module.css";
export default forwardRef((props: any, ref) => {
  const inputRef = useRef<HTMLSelectElement | null>(null);
  const viewKCIContext = useContext(ViewKCIContext);
  const countryContext = useContext(CountryContext);

  const onInputBoxChanged = (input: React.ChangeEvent<HTMLSelectElement>) => {
    viewKCIContext?.setCountry(input.target.value);
    props.parentFilterInstance((instance: any) => {
      instance.onFloatingFilterChanged("equals", input.target.value);
    });
  };

  return (
    <Fragment>
      <div className="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper">
        <select
          ref={inputRef}
          value={viewKCIContext?.country}
          onChange={onInputBoxChanged}
          className={Styles.selectFilter}
        >
          {countryContext?.countries?.map(({ orgcode, name }, i) => (
            <option key={i} value={orgcode}>
              {name}
            </option>
          ))}
        </select>
      </div>
    </Fragment>
  );
});
